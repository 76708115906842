<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    aria-hidden="true"
  >
    <g fill="currentColor">
      <path
        d="M4.708 24.102a19.185 19.185 0 1 1 19.186 19.185A19.185 19.185 0 0 1 4.708 24.102m-4.263 0A23.342 23.342 0 1 0 7.282 7.49 23.449 23.449 0 0 0 .445 24.102Z"
      />
      <path
        d="m26.239 24.102-7.035 7.035a2.025 2.025 0 0 0 .213 3.2 2.238 2.238 0 0 0 2.878-.213l8.42-8.527a2.025 2.025 0 0 0 0-2.984l-8.42-8.527a2.238 2.238 0 0 0-2.878-.213 2.025 2.025 0 0 0-.213 3.2Z"
      />
    </g>
  </svg>
</template>
